#invoice-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1em;
}

.invoice-header-container {
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 1em;
  margin-bottom: 2em;
}

.invoice-header-item {
  display: block;
  // flex-direction: column;

  img {
    max-width: 100%;
    margin-bottom: 0.5em;
  }

  div {
    display: grid;
    grid-template-columns: 8em auto;
    align-items: start;
    padding-top: 0.5em;
  }
}

.invoice-header-title {
  grid-column-start: span 2;
}

.invoice-table-container {
  margin: 5px auto;
  width: 100%;
  text-transform: uppercase;
}