.report-wrapper-header {
  display: flex;
  align-items: center;
  background-color: black;
  color: white;
  border: 1px solid grey;
  justify-content: space-between;
}

.action-section {
  display: flex;
  justify-content: flex-end;
}

.report-section {
  border: 1px solid grey;
  padding: 5px;
}

.report-wrapper {
  border: 2px solid black;
}
