.section {
  .header-section {
    border: none;
  }
  .other-section {
    padding: 20px;
  }
  border: 1px solid grey;
  margin: 0.25em 0;
  padding: 0.5em;
}
