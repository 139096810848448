.header {
  display: flex;
  justify-content: center;
  padding: 0.5em;
  margin-bottom: 0;
}

.field {
  margin: 1em !important;
}

.container {
  padding: 0px;
  margin: 1em;
}

.button {
  display: flex;
  justify-content: center;
  margin: 2em !important;
}

.main-login-container {
  display: flex;
  justify-content: center;
  border: 5px solid red;
  border-radius: 1%;
  margin-bottom: 1em;
}

#login-register-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  input {
    text-transform: none;
  }
}
