.portal-detail-container {
  display: flex;
  flex-grow: 1;
  width: 100%;
}

.portal-appbar {
  top: 63px !important;
  width: 100%;
}

.portal-content {
  background-color: white;
  margin-top: 50px;
  flex-grow: 1;
}
