// .MuiTableCell-head {
//   color: white !important;
//   background-color: black !important;
//   &:hover {
//     background-color: rgb(255, 0, 0) !important;
//   }
// }

// .MuiTableCell-body {
//   &:hover {
//     background-color: lightcyan;
//   }
// }

// .MuiTableRow-Body {
//   border: none;
//   margin: 10px;
//   &:hover {
//     background-color: lightgoldenrodyellow !important;
//   }
// }

.table-container {
  margin: 5px auto;
  width: 100%;
  // border: 1px solid black;
  // padding: 5px;
}

.no-margin-table {
  width: 100%;
  margin: none;
}

tbody.MuiTableBody-root > tr:hover {
  // background-color: #efefef;
  background-color: darkgrey;
  // color: white;
}
