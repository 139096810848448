.form-control {
  width: 80% !important;
  height: 80% !important;
  margin: 2px 0px !important;
  padding: 2px;
  border: 1px solid black !important;
}

.MuiSelect-select {
  padding-left: 24px !important;
}
