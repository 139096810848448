.row {
  // border: 1px solid red;
  //display: grid;
  .row-section {
    // border: 1px solid green;
    margin: 1px 0px;
    padding: 1px;
    .row-section-item {
      border: 0px solid black;
      padding: 2px;
      margin: 1px 0px;
      //grid-column-start: 1;
    }
  }
}
