.tripleInputTele {
  display: flex;
  width: 80%;
}

.tripleInputTeleRow {
  margin-right: 1.7em;
}

.areaCode {
  margin-left: 5px;
  margin-right: 5px;
  width: 4em;
}

.firstThreeRow {
  margin-right: 10px;
}

.firstThree {
  width: 4em;
}

.lastFourRow {
  display: flex;
  margin-right: 10px;
}

.lastFour {
  margin-left: 5px;
  width: 5em;
}
