.date-time-picker {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.picker {
  width: 100%;
}

.clear-button {
  align-self: flex-end;
  span {
    svg {
      width: 0.8em;
    }
  }
}