#view-order {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 20px 15px;
  height: 100vh;
}

.section-header {
  padding-left: 2em !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  border: 1px solid black;
  height: 2.5rem;
  display: flex;
  align-items: center;
}

.main-info {
  background-color: cornflowerblue;
}

.client-info {
  background-color: lightgreen;
}

.service-info {
  background-color: lightyellow;
}

.attachment {
  background-color: lightcoral;
}

.examination-details {
  background-color: lightpink;
}

.sub-section {
  background-color: lightblue;
  // border-top: 1px solid grey;
  border-left: 1px solid grey;
  border-right: 1px solid grey;
  border-bottom: 1px solid grey;
}

.sub-section__input{
  background-color: white;
  // border-top: 1px solid grey;
  border-right: 1px solid grey;
  border-bottom: 1px solid grey;
  padding-left: 0.5rem;
  display: flex;
  align-items: center;
  height: 100%;
}

.section {
  margin-bottom: 5px !important;
  padding-bottom: 10px !important;
  border: 2px solid grey;
}

.sub-section-header {
  justify-content: center;
  display: flex;
  border: 1px solid black;
  margin: 2px 0px !important;
}
.web-request {
      background-color: lightcyan
    }
.special-test {
      background-color: lightslategrey
    }

    .web-section-top {
      border-top:1px solid grey;
    }

    .sub-section__full {
      border-top: 1px solid grey;
      padding-left: 0.5rem;
    }

    .checkBox {
      padding-left: 10px !important;
    }

.view-order-button {
  margin-right: 10px !important;
}