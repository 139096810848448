.section {
  border: 1px solid grey;
  margin: 0.25em 0em;
  padding: 0.5em;
}

.main-section {
  margin: 0 auto;
}

.MuiGrid-container {
  padding: 0 !important;
}
