.basic-textarea {
  width: 100%;
  height: 100%;
  resize: none;
  &:focus {
    border-color: lightblue;
    outline: 0 none;
  }
}

.error-textarea {
  border-color: red;
}
