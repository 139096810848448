.section-container {
  border: 1px solid rgba(17, 80, 240, 0.5);
  border-radius: 1%;
  padding-bottom: 1em;
  margin: 1em auto;
  background: rgba(182, 182, 172, 0.057);
  width: 98%;
}

.save-button {
  margin-top: 2em;
}

.address-2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}