.header-container {
  display: flex;
  flex-direction: column;
  // height: 3em;
  margin-bottom: 0em;
  margin-top: 1em;

  .title__text {
    display: flex;
    color: black;
    justify-content: center;
    margin: 0;
  }
  .saved__text {
    display: flex;
    justify-content: center;
    margin: 0;
    // color: green;

    &--success {
      color: green;
    }

    &--fail {
      color: red;
    }
  }
}
