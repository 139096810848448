// @import "~normalize.css/normalize.css";

* {
  box-sizing: border-box;
}

// body {
//   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
//     "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
// }

.layout {
  display: flex;
  min-height: 100vh;
}

input, textarea, li {
  text-transform: uppercase;
}

.swal2-input {
  text-transform: none;
}
// body {
// margin-top: 5em !important;
// margin-left: 72px !important;
// }

