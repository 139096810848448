.buttons-menu {
  display: flex;
  justify-content: center;
}

.text-link {
  color: inherit;
  text-decoration: inherit;
  align-self: center;
}

.active {
  color: red;
}
