.boldFont {
  font-weight: bold !important;
}

.flexEnd {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
  padding-right: 0.5rem;
}

.toUpperCase {
  text-transform: uppercase;
}

.minHeight {
  min-height: 2rem;
}
