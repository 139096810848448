.section-container {
  border: 1px solid rgba(17, 80, 240, 0.5);
  border-radius: 1%;
  padding-bottom: 1em;
  margin: 1em 1em;
  background: rgba(253, 253, 252, 0.057);
}

.save-button {
  margin-top: 2em;
}
